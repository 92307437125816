// 开发环境域名
const host_development = 'http://shopkxbfnsm4.com'
//https://php-b2csaas.yixiangonline.com

export default {
    // 版本
    version: '3.2.0',
    baseURL: process.env.NODE_ENV == 'production' ? '' : host_development,
    tencentMapKey: 'FWEBZ-WHSHV-IRFPO-UNMRL-5EUWV-BFBFW'
}
